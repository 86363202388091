import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    useQueryForumMessages,
    useMutationForumMessageAdd
} from '../../queries';
import {Loader} from '../../utils/';
import {toast} from "react-toastify";
import {AuthContext} from "../../contexts";
import {
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    MessageSeparator,
    Avatar
} from "@chatscope/chat-ui-kit-react";
import {Avatar as CustomAvatar} from "../../components";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

const ForumPageBrief = ({socket}) => {
    const {id} = useParams();
    const {data: messages, isLoading} = useQueryForumMessages(id);
    const {mutate} = useMutationForumMessageAdd(id);
    const {user} = useContext(AuthContext);
    const [messagesList, setMessagesList] = useState(messages || []);

    useEffect(() => {
        if (messages) {
            setMessagesList(messages);
        }
    }, [messages]);

    useEffect(() => {
        const handleReceiveMessage = (data) => {
            console.log("receive_message", JSON.stringify(data));
            
            setMessagesList((prevMessages) => {
                return prevMessages.some(message => message.id === data.id)
                    ? prevMessages
                    : [...prevMessages, data];
            });
        };

        socket.on("receive_message", handleReceiveMessage);
        return () => socket.off("receive_message", handleReceiveMessage);
    }, [socket]);

    const onSubmit = async (data) => {
        const payload = {message: data};
        mutate(payload, {
            onSuccess: (posted) => {
                const newMessage = posted.data.data;
                socket.emit('send_message', newMessage);
                console.log("posted "+JSON.stringify(newMessage));
                setMessagesList(prev => [...prev, newMessage]);
            },
            onError: () => toast.error('Une erreur est survenue')
        });
    };

    const isAvatarSpacer = (message, index) => {
        if (index + 1 === messagesList.length) return false;
        return message.user.username === messagesList[index + 1].user.username;
    };

    const getPosition = (message, index) => {
        const messageCount = messagesList.length;
        if (messageCount <= 1) return "single";
        if (index === 0) {
            return message.user.username !== messagesList[index + 1].user.username ? "single" : "first";
        }
        if (index === messageCount - 1) return "last";

        const prevUser = messagesList[index - 1].user.username;
        const nextUser = messagesList[index + 1].user.username;
        const currentUser = message.user.username;

        if (prevUser !== currentUser && currentUser !== nextUser) return "single";
        if (prevUser !== currentUser) return "first";
        if (currentUser !== nextUser) return "last";
        return "normal";
    };

    const renderMessageSeparator = (message, index) => {
        if (index === 0) return true;
        return !moment(message.date).isSame(messagesList[index - 1].date, 'day');
    };

    const renderMessageHeader = (message, index) => {
        if (index === 0) return true;
        return message.user.username !== messagesList[index - 1].user.username;
    };

    const renderAvatar = (message, index) => {
        if (index + 1 === messagesList.length) return true;
        return message.user.username !== messagesList[index + 1].user.username;
    };

    return (
        <div style={{position: "relative", height: "500px"}}>
            <ChatContainer>
                {isLoading ? (
                    <Loader/>
                ) : (
                    <MessageList>
                        {messagesList?.length > 0 && messagesList.map((message, index) => (
                            <React.Fragment key={message.id}>
                                {renderMessageSeparator(message, index) && (
                                    <MessageSeparator>
                                        {moment(message.date).locale('fr').format('DD MMMM YYYY')}
                                    </MessageSeparator>
                                )}
                                <Message
                                    model={{
                                        message: message.message,
                                        direction: message.user.id === user.id ? "outgoing" : "incoming",
                                        position: getPosition(message, index)
                                    }}
                                    avatarSpacer={isAvatarSpacer(message, index)}
                                >
                                    {renderMessageHeader(message, index) && (
                                        <Message.Header>{message.user.username}</Message.Header>
                                    )}
                                    {renderAvatar(message, index) && (
                                        <Avatar>
                                            <CustomAvatar userAvatar={message.user.id === user.id ? user.avatar : message.user.avatar}/>
                                        </Avatar>
                                    )}
                                    <Message.CustomContent className={`${message.user.id === user.id && "text-white"}`}>
                                        <div dangerouslySetInnerHTML={{__html: message.message}}/>
                                    </Message.CustomContent>
                                    {renderAvatar(message, index) && (
                                        <Message.Footer>{moment(message.date).format('HH:mm')}</Message.Footer>
                                    )}
                                </Message>
                            </React.Fragment>
                        ))}
                    </MessageList>
                )}
                <MessageInput placeholder="" attachButton={false} onSend={onSubmit}/>
            </ChatContainer>
        </div>
    );
};

export default ForumPageBrief;