// External librairies
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

// Instance
import instance from "../../config/axiosconfig";

// Hooks
import { useLocalStorage } from "../../hooks/useLocalStorage";

export const useMutationLogin = () => {
	const [user, setUser] = useLocalStorage("user", null);
	const navigate = useNavigate();
	return useMutation({
		mutationKey: ["mutateLogin"],
		mutationFn: (credentials) => {
			return instance.post("/auth/login", credentials);
		},
		onSuccess: (response) => {
			setUser(response.data.data);
			navigate("/");
			window.location.reload();
		},
		onError: (error) => {
			if (error.response.status === 401) {
			toast.error(error.response.data.message, {delay: 0});
			} else {
				toast.error("Une erreur inconnue est survenue.", {delay: 0});
			}
		}
	});
};

