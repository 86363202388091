// External libraries
import React from 'react';
import { NavLink } from 'react-router-dom';

// Query
import { useQuerySurvey } from '../../queries';

// Components
import { ActionHeader, CardSurvey } from '../../components';

// Misc
import { Loader, Transition } from '../../utils';

const SurveyPage = () => {
	const { isLoading, data: surveys } = useQuerySurvey();
	
	const actionData = [{
		id: 0,
		route: 'create',
		label: 'Rédiger un questionnaire'
	}];

	const renderSurveyGrid = () => (
		<div className="w-full grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
			{surveys.map(({ id, title, sur_created, sur_updated }) => (
				<div key={id} className="flex-1">
					<NavLink to={`${id}/brief`}>
						<CardSurvey
							title={title}
							createDate={sur_created}
							updateDate={sur_updated}
						/>
					</NavLink>
				</div>
			))}
		</div>
	);

	const renderEmptyState = () => (
		<div className="w-full h-full flex justify-center">
			<h3 className="mt-[20vh] text-xl lg:text-[2.6rem] opacity-[.18] text-center leading-[1.2em]">
				Il n'y actuellement aucun questionnaire
			</h3>
		</div>
	);

	return (
		<>
			<div>
				<ActionHeader
					icon="survey"
					title="Questionnaires"
					hasReturn={false}
					actionData={actionData}
				/>
			</div>
			<div className="w-full h-full flex justify-center">
				{isLoading ? (
					<Loader />
				) : (
					<div className="w-full">
						<Transition>
							{surveys ? renderSurveyGrid() : renderEmptyState()}
						</Transition>
					</div>
				)}
			</div>
		</>
	);
};

export default SurveyPage;